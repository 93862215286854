(function($) {
  'use strict';

  var c = localStorage.getItem('c');

  // if cookies have been accepted set the padding of the copyright column to 0

  if(c === '1') {
    $('.copyright').css("padding", "0");
  }

  // display banner if user has not consented

  if(c == null) {
  	$('.cookie-banner').css("display", "block");
  }

  // set localstorage confirming consent and hide banner

  $('.accept').on('click', function () {
  	if(c == null) {
	  	c = 1;
	  	localStorage.setItem('c', c);
	}
    
    $('.cookie-banner').slideToggle();
    $('.copyright').animate({
      "padding" : 0
    }, "slow");
  });

  // hide banner if user has chosen to dismiss the banner

  $('.dismiss').on('click', function (event) {
    event.preventDefault();
    $('.cookie-banner').slideToggle();
    $('.copyright').animate({
      "padding" : 0
    }, "slow");
  });
  
})(jQuery);