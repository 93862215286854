(function($) {

	var n = $( "section" ).length;
	var i = 1;
	var section_blocks = "";
	//console.log(n);
	while (i <= n) {
		section_blocks += "<div class='section-block'><a class='section-link' href='#section-" + i + "' title='Go to section " + i + "'><i class='fas fa-angle-down'></i></a></div>";
		i++;
	}
	$(".section-bar").html( section_blocks );

    // handle links with @href started with '#' only
	$(document).on('click', 'a[href^="#"].section-link', function(e) {
	    // target element id
	    var id = $(this).attr('href');

	    // target element
	    var $id = $(id);
	    if ($id.length === 0) {
	        return;
	    }

	    // prevent standard hash navigation (avoid blinking in IE)
	    e.preventDefault();

	    // top position relative to the document
	    var header = 80; // the space taken up by the fixed header
	    var pos = $id.offset().top - header;

	    // animated top scrolling
	    $('body, html').animate({scrollTop: pos}, 700);
	});

})(jQuery); // Fully reference jQuery after this point.