(function($) {
    if ($('.back-to-top').length) {
        var scrollTrigger = 100, // px
            backToTop = function () {
                var scrollTop = $(window).scrollTop();
                //console.log($('.back-to-top').length);
                if (scrollTop > scrollTrigger) {
                    $('.back-to-top').show();
                } else {
                    $('.back-to-top').hide();
                }
            };
        backToTop();
        $(window).on('scroll', function () {
            backToTop();
        });
        $('.scrollToTop').on('click', function (e) {
            e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
        });
    }
}
)(jQuery);