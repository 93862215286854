// When the recaptcha is expired, run the following callback
var verifyExpiredCallback = function() {
    // Disable the submit button.
    document.getElementById('submit').disabled = true;
};

// When the recaptcha is validated, run the following callback
var recaptchaVerifyCallback = function() {
    // Enable the submit button.
    document.getElementById('submit').disabled = false;
};

(function($) {

	function validate_email(email) {
		var regex = /(.+)@(.+){2,}\.(.+){2,}/;
		if(!regex.test(email)) {
		    return false;
		} else {
		    return true;
		}
	}

	$(".submit").click( function(e) {
		e.preventDefault();

		var name = $('#first-name').val(),
		email = $('#email').val(),
		project_details = $('#project-details').val(),
		submit_button = $('.submit');

		if(name === "") {
			$('#first-name').focus();
			$('#first-name').attr('title', 'Please enter your first name');
			$('.first-name-error').text('Please enter your first name');
			$('.first-name-error').show();
		}

		var email_check = validate_email(email);
		if(!email_check) {
			$('#email').focus();
			$('#email').attr('title', 'Please enter a valid email address');
			$('.email-error').text('Please enter a valid email address');
			$('.email-error').show();
		} else {
			$.ajax({
				type : 'POST',
				dataType : "json",
				url : '/wp/wp-admin/admin-ajax.php',
				data : {
			       action: "send_email",
			       name: name,
			       email: email,
			       project_details: project_details
			    },
				success: function( response ) {
					//console.log($response);
					// if there is an error
					if ( response.error !== false) {
						$('.email-send-error').text('Unfortunately, there has been an error with submitting your email address.');
						$('.email-send-error').slideToggle();
						$('.email-send-error').delay(4000).slideToggle();
					}
					// if there are no errors 
					if ( response.error === false) {
						submit_button.attr( 'disabled', true );
						$('.error').hide();
						$('.confirmation').text('Your request has now been sent.');
						$('.confirmation').slideToggle();
						$('.confirmation').delay(4000).slideToggle();
					}
				}
			});
		}
	});
})(jQuery);