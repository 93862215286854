(function($) {

	$(window).scroll(function() {
		var currentScroll = $(this).scrollTop();
		if(currentScroll === 0) {
			$('.brand').css('height', '60px');
			$('.offcanvas-collapse').css('top', '60px');
			$('.logo').css('margin-top', '7px');
			$('.image-container').css('padding-top', '12px');
			$('.single article').css('padding-top', '12px');	
		} else {
			$('.brand').css('height', '80px');
			$('.offcanvas-collapse').css('top', '80px');
			$('.logo').css('margin-top', '15px');
			$('.image-container').css('padding-top', '32px');
			$('.single article').css('padding-top', '32px');
		}
	});

})(jQuery); // Fully reference jQuery after this point.